import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GradientButton } from '../buttons/gradient_button';

export const DashboardActions = ({ isMerchant = false }) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	return (
		<div className="mb-[40px]" dir={langDir}>
			<h1 className="text-lg font-bolder mt-[40px] mb-[10px]">{t('pages.dashboard.actions.title')}</h1>
			<div className="flex text-lg">
				<GradientButton text={t('buttons.send')} onClick={() => navigate('/send')} iconPath="icons/arrow_out.svg" className="me-2" />
				<GradientButton text={t('buttons.deposit')} onClick={() => navigate('/deposit')} iconPath="icons/arrow_in.svg" />
			</div>

			{!isMerchant && <GradientButton text={t('buttons.cardToCard')} onClick={() => navigate('/card-to-card')} iconPath="icons/card_to_card.svg" />}
			<GradientButton
				text={t('buttons.swapCurrencies')}
				onClick={() => navigate('/swap-currency')}
				iconPath="icons/button_swap_currencies_black.svg"
			/>
			<GradientButton text={t('buttons.faq')} onClick={() => navigate('/faq')} />
		</div>
	);
};
