import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import './i18n';

import { isMobile } from 'react-device-detect';

import { getStorageToken, handleTokenExpiration } from './utils/functions/token';

import Login from './pages/auth/login';
import Register from './pages/auth/register.jsx';

import Dashboard from './pages/dashboard/dashboard';

import NotFound404 from './pages/utils/404';
import ResultPage from './pages/utils/result-page';
import SuspendedPage from './pages/utils/suspended';
import OnlyMobilePage from './pages/utils/onlyMobile.jsx';

import UserProfile from './pages/auth/userProfile.jsx';
import ForgotPassword from './pages/auth/forgotPassword.jsx';

import TermsAndConditions from './pages/terms/terms-and-conditions';
import PrivacyPolicy from './pages/terms/privacy-policy';
import CardTermsAndConditions from './pages/terms/card-terms-and-conditions.jsx';
import CardPrivacyPolicy from './pages/terms/card-privacy-policy.jsx';
import KYCProcess from './pages/kyc/kycProcess.jsx';

import IbanTermsAndConditions from './pages/terms/iban-terms-and-conditions.jsx';

import WelcomePage from './pages/auth/welcome.jsx';
import DepositPage from './pages/actions/deposit/deposit.jsx';
import SendPage from './pages/actions/send/send.jsx';

import SetPincode from './pages/auth/setPincode.jsx';
import Withdrawal from './pages/actions/send/withdrawal.jsx';
import Menu from './pages/menu/menu.jsx';
import Notifications from './pages/messages/messages.jsx';
import ChangePassword from './pages/auth/changePassword.jsx';

import FAQ from './pages/faq/faq.jsx';

import Cards from './pages/cards/cards.jsx';
import CardDepositPage from './pages/cards/card_deposit.jsx';
import WireTransfer from './pages/actions/deposit/wire_transfer.jsx';
import PaytoraMemberTransfer from './pages/actions/send/paytora_transfer.jsx';
import IbanTransactions from './pages/transactions/ibanTransactions.jsx';
import SwapCurrency from './pages/swap/swapCurrency.jsx';
import CardTransactions from './pages/transactions/cardsTransactions.jsx';
import PayToraTransactions from './pages/transactions/paytoraTransactions.jsx';
import EditProfileAddress from './pages/auth/editProfileAddress.jsx';
import CardToCard from './pages/cards/cardToCard.jsx';
import EStatements from './pages/eStatements/eStatements.jsx';
import CryptoExchangePage from './pages/actions/deposit/cryptoExchange.jsx';

import KYCInvestigate from './pages/kyc/kycInvestigate.jsx';
import Loader from './pages/utils/loader.jsx';

import { getUserStatuses } from './services/user.js';
import MaintenancePage from './pages/utils/maintenance.jsx';

const AuthenticatedRoute = ({ element, kycRequired = false, onlyIndividual = false }) => {
	const token = getStorageToken();

	const [userInfo, setUserInfo] = useState({
		kycLevel: 'none',
		isSuspended: false,
		hasPincode: true,
		userType: 'individual',
	});

	const [loading, setLoading] = useState(true); // Track loading state

	useEffect(() => {
		const fetchUserStatuses = async () => {
			const response = await getUserStatuses();

			if (response.isSuccess) setUserInfo(response.data);

			setLoading(false);
		};
		fetchUserStatuses();
	}, []);

	useEffect(() => {
		if (token) {
			handleTokenExpiration(token);
		}
	}, [token]);

	if (loading) return <Loader />;

	if (!token) return <WelcomePage />;

	if (onlyIndividual && userInfo.userType !== 'individual') return <Dashboard />;

	if (userInfo.isSuspended) return <SuspendedPage />;

	if (userInfo.kycLevel === 'declined') return <KYCProcess isStepTwo={true} currentKycLevel={userInfo.kycLevel} />;

	if (userInfo.kycLevel === 'none') return <KYCProcess currentKycLevel={userInfo.kycLevel} />;

	if (userInfo.kycLevel === 'investigate') return <KYCInvestigate currentKycLevel={userInfo.kycLevel} />;

	if (userInfo.kycLevel === 'partial' && kycRequired) return <KYCProcess isStepTwo={true} currentKycLevel={userInfo.kycLevel} />;

	if (!userInfo.hasPincode) return <SetPincode />;

	return element;
};

function RoutesWrapper() {
	const location = useLocation();
	const isRegisterPage = location.pathname === '/register';

	if (!isMobile && !isRegisterPage) {
		return <OnlyMobilePage />; // Redirect desktop users unless on /register
	}

	// return <MaintenancePage />;

	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/terms" element={<TermsAndConditions />}></Route> {/** Translation: Check if requried */}
			<Route path="/privacy" element={<PrivacyPolicy />}></Route> {/** Translation: Check if requried */}
			<Route path="/success" element={<ResultPage isSuccess={true} />}></Route>
			<Route path="/failed" element={<ResultPage />}></Route>
			<Route path="/404" element={<NotFound404 />} />
			<Route path="*" element={<Navigate to="/404" />} />
			{/* Authenticated Routes */}
			<Route path="/" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={false} />} />
			<Route path="/kyc" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />
			<Route path="/menu" element={<AuthenticatedRoute element={<Menu />} kycRequired={false} />} />
			<Route path="/profile" element={<AuthenticatedRoute element={<UserProfile />} kycRequired={true} />} />
			<Route path="/profile/address/edit" element={<AuthenticatedRoute element={<EditProfileAddress />} kycRequired={true} />} />
			<Route path="/profile/password" element={<AuthenticatedRoute element={<ChangePassword />} kycRequired={false} />} />
			<Route path="/messages" element={<AuthenticatedRoute element={<Notifications />} kycRequired={false} />} />
			<Route path="/deposit" element={<AuthenticatedRoute element={<DepositPage />} kycRequired={false} />} />
			<Route path="/crypto/exchange" element={<AuthenticatedRoute element={<CryptoExchangePage />} kycRequired={false} />} />
			<Route path="/send" element={<AuthenticatedRoute element={<SendPage />} kycRequired={true} />} />
			<Route path="/send/withdrawal" element={<AuthenticatedRoute element={<Withdrawal />} kycRequired={true} />} />
			<Route path="/send/paytora-member/:currencyCode?" element={<AuthenticatedRoute element={<PaytoraMemberTransfer />} kycRequired={true} />} />
			<Route path="/swap-currency" element={<AuthenticatedRoute element={<SwapCurrency />} kycRequired={false} />} />
			<Route path="/cards" element={<AuthenticatedRoute element={<Cards />} kycRequired={false} onlyIndividual={true} />} />
			<Route path="/card/deposit" element={<AuthenticatedRoute element={<CardDepositPage />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/card/terms" element={<AuthenticatedRoute element={<CardTermsAndConditions />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/card/privacy" element={<AuthenticatedRoute element={<CardPrivacyPolicy />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/card-to-card" element={<AuthenticatedRoute element={<CardToCard />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/iban/details" element={<AuthenticatedRoute element={<WireTransfer />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/iban/terms" element={<AuthenticatedRoute element={<IbanTermsAndConditions />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/transactions/paytora/:currency?" element={<AuthenticatedRoute element={<PayToraTransactions />} kycRequired={false} />} />
			<Route path="/transactions/cards" element={<AuthenticatedRoute element={<CardTransactions />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/transactions/iban" element={<AuthenticatedRoute element={<IbanTransactions />} kycRequired={true} onlyIndividual={true} />} />
			<Route path="/faq" element={<AuthenticatedRoute element={<FAQ />} kycRequired={false} />} />
			<Route path="/e-statements" element={<AuthenticatedRoute element={<EStatements />} kycRequired={true} />} />
		</Routes>
	);
}

function App() {
	return (
		<Router>
			<RoutesWrapper />
		</Router>
	);
}

export default App;
